import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPrivacyPolicyData } from "../../redux/actions/homeAction";
import Footer from "../../components/footer/Footer";

const PrivacyPolicyOne = () => {
  const dispatch = useDispatch();
  const { privacyData, homePageData } = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(getPrivacyPolicyData("privacy-policy-1"));
  }, []);

  return (
    <>
      <div className="privacy-wrapper py-5 my-2">
        <div
          className="container"
          dangerouslySetInnerHTML={{ __html: privacyData?.content }}
        ></div>
      </div>
      <Footer homePageData={homePageData} />
    </>
  );
};

export default PrivacyPolicyOne;
