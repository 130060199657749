import React, { useEffect, useRef, useState } from "react";
import "./navbar.scss";
import { Link, useNavigate } from "react-router-dom";
import { FaRegUser } from "react-icons/fa6";
import ResponsiveNavbar from "./ResponsiveNavbar";
import { Link as ScrollLink } from "react-scroll";
import {
  getHomePageData,
  getUserProfileData,
} from "../../redux/actions/homeAction";
import { useDispatch, useSelector } from "react-redux";
import TranslateComponent from "../translation/Translation.jsx";

const Navbar = ({ pathname }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(true); // State to track if the screen is large
  const wrapperRef = useRef(null);
  const wrapperRefnew = useRef(null);
  const [activeMenuItem, setActiveMenuItem] = useState("homeSection");
  const isAuthenticated = localStorage.getItem("loyalty_token");
  const { updateProfile, userDelete, homePageData, subsciptionCharge } =
    useSelector((state) => state.home);
  useOutsideAlerter(wrapperRef, wrapperRefnew);

  useEffect(() => {
    dispatch(getHomePageData());
    updateWindowDimensions(); 
    window.addEventListener("resize", updateWindowDimensions); // Listen to window resize events

    return () => {
      window.removeEventListener("resize", updateWindowDimensions); // Remove event listener on component unmount
    };
  }, [dispatch]);
  useEffect(() => {
    if (isAuthenticated && pathname === "/dashboard/main") {
      setShowNavbar(true); 
    }
  }, [isAuthenticated, pathname]);
  

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserProfileData(navigate));
    }
  }, [dispatch, updateProfile, userDelete, subsciptionCharge]);

  // Function to update window dimensions and set isLargeScreen state
  const updateWindowDimensions = () => {
    setIsLargeScreen(window.innerWidth > 1000);
  };

  function useOutsideAlerter(ref, refnew) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !refnew.current.contains(event.target)
        ) {
          setShowNavbar(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return pathname?.split("/")[1] === "add-user" ? null : (
    <div
      className={`navbar-wrapper ${
        pathname === "/dashboard/main" ||
        pathname === "/dashboard/business" ||
        pathname === "/dashboard/my-qr-code" ||
        pathname === "/dashboard/my-scans" ||
        pathname === "/dashboard/rewards" ||
        pathname === "/dashboard/address" ||
        pathname === "/dashboard/notification" ||
        pathname === "/dashboard/coupon" ||
        pathname === "/dashboard/plans" ||
        pathname === "/dashboard/product-category" ||
        pathname === "/dashboard/how-to-use" ||
        (pathname === "/dashboard/profile" ? "boxShadowUnsert" : null)
      }`}
      ref={wrapperRefnew}
    >
      <div
        className={
          pathname === "/dashboard/main" ||
          pathname === "/dashboard/business" ||
          pathname === "/dashboard/my-qr-code" ||
          pathname === "/dashboard/my-scans" ||
          pathname === "/dashboard/rewards" ||
          pathname === "/dashboard/address" ||
          pathname === "/dashboard/notification" ||
          pathname === "/dashboard/coupon" ||
          pathname === "/dashboard/plans" ||
          pathname === "/dashboard/my-staff" ||
          pathname === "/dashboard/product-category" ||
          pathname === "/dashboard/how-to-use" ||
          pathname === "/dashboard/profile"
            ? "dashboardnav-wrapper"
            : "container"
        }
      >
        <div className="navbar-menu-item">
          <Link to="/">
            <div className="navbar-logo">
              <img src={homePageData?.logo} alt="" />
            </div>
          </Link>

          {pathname === "/login" ||
          pathname === "/forgot-password" ||
          pathname === "/forgot-verify-otp" ||
          pathname === "/change-password" ||
          pathname === "/register" ||
          pathname === "/verify-otp" ||
          pathname === "/subscription" ||
          pathname === "/thank-you" ||
          pathname === "/payment" ||
          pathname === "/subscription" ? <TranslateComponent /> : showNavbar ? null : (
            <>
                {isLargeScreen ?<></>: <TranslateComponent />}
              <i
                className="fa fa-bars"
                aria-hidden="true"
                style={{order:2}}
                onClick={() => setShowNavbar(true)}
                ></i>
            </>
          )}

          <ResponsiveNavbar
            setShowNavbar={setShowNavbar}
            showNavbar={showNavbar}
            pathname={pathname}
            wrapperRef={wrapperRef}
            isAuthenticated={isAuthenticated}
          />

          {pathname === "/dashboard/main" ||
          pathname === "/dashboard/business" ||
          pathname === "/dashboard/my-qr-code" ||
          pathname === "/dashboard/my-scans" ||
          pathname === "/dashboard/rewards" ||
          pathname === "/dashboard/address" ||
          pathname === "/dashboard/notification" ||
          pathname === "/dashboard/coupon" ||
          pathname === "/dashboard/plans" ||
          pathname === "/dashboard/my-staff" ||
          pathname === "/dashboard/product-category" ||
          pathname === "/dashboard/how-to-use" ||
          pathname === "/dashboard/profile" ? (
            <div className="translateCom">
            <TranslateComponent />
            <div className="download-icons">
              <a
                href="https://play.google.com/store/apps/details?id=com.LoyaltyCardGenerator&pli=1"
                target="_blank"
              >
                <img src="/assets/googleplay.png" alt="" />
              </a>
              <a
                href="https://apps.apple.com/us/app/loyalty-card-generator/id6477577291"
                target="_blank"
              >
                <img
                  src="/assets/appstore.png"
                  alt=""
                  style={{ marginLeft: "20px" }}
                />
              </a>
            </div>
            </div>
            
          ) : pathname === "/payment" || pathname === "/subscription" ? (
            <div className="download-icons">
              <button className="logout_btn" onClick={handleLogout}>
                LOG OUT
              </button>
            </div>
          ) : pathname === "/login" ||
            pathname === "/forgot-password" ||
            pathname === "/forgot-verify-otp" ||
            pathname === "/change-password" ||
            pathname === "/register" ||
            pathname === "/verify-otp" ||
            pathname === "/thank-you" ||
            pathname.split("/")[1] === "add-user" ? null : pathname ===
              "/privacy-policy" ||
            pathname === "/terms-condition" ||
            pathname === "/privacy-policy-1" ? (
            <ul className="navbar-list">
              <li className="navbar-item">
                <Link
                  to="/"
                  className={`navbar-link ${
                    activeMenuItem === "homeSection" ? "active" : ""
                  }`}
                  onClick={() => setActiveMenuItem("homeSection")}
                >
                  Home
                </Link>
              </li>
              <li className="navbar-item">
                <Link
                  to="/"
                  className={`navbar-link ${
                    activeMenuItem === "aboutUs" ? "active" : ""
                  }`}
                  onClick={() => setActiveMenuItem("aboutUs")}
                >
                  About us
                </Link>
              </li>
              <li className="navbar-item">
                <Link
                  to="/"
                  className={`navbar-link ${
                    activeMenuItem === "howItWork" ? "active" : ""
                  }`}
                  onClick={() => setActiveMenuItem("howItWork")}
                >
                  How it work
                </Link>
              </li>
              <li className="navbar-item">
                <Link
                  to="/"
                  className={`navbar-link ${
                    activeMenuItem === "pricing" ? "active" : ""
                  }`}
                  onClick={() => setActiveMenuItem("pricing")}
                >
                  Pricing
                </Link>
              </li>
              <li className="navbar-item">
                <Link
                  to="/"
                  className={`navbar-link ${
                    activeMenuItem === "contactUs" ? "active" : ""
                  }`}
                  onClick={() => setActiveMenuItem("contactUs")}
                >
                  Contact us
                </Link>
              </li>
              {isAuthenticated ? (
                <Link to="/dashboard/main">
                  <button className="login-btn">
                    <FaRegUser /> <span>Dashboard</span>
                  </button>
                </Link>
              ) : (
                <Link to="/login">
                  <button className="login-btn">
                    <FaRegUser /> <span>Login Your Account</span>
                  </button>
                </Link>
              )}
            </ul>
          ) : (
            <ul className="navbar-list">
              <li className="navbar-item">
                <ScrollLink
                  to="homeSection"
                  smooth={true}
                  duration={50}
                  className={`navbar-link ${
                    activeMenuItem === "homeSection" ? "active" : ""
                  }`}
                  onClick={() => setActiveMenuItem("homeSection")}
                >
                  Home
                </ScrollLink>
              </li>
              <li className="navbar-item">
                <ScrollLink
                  to="aboutUs"
                  smooth={true}
                  duration={50}
                  className={`navbar-link ${
                    activeMenuItem === "aboutUs" ? "active" : ""
                  }`}
                  onClick={() => setActiveMenuItem("aboutUs")}
                >
                  About us
                </ScrollLink>
              </li>
              <li className="navbar-item">
                <ScrollLink
                  to="howItWork"
                  smooth={true}
                  duration={50}
                  className={`navbar-link ${
                    activeMenuItem === "howItWork" ? "active" : ""
                  }`}
                  onClick={() => setActiveMenuItem("howItWork")}
                >
                  How it work
                </ScrollLink>
              </li>
              <li className="navbar-item">
                <ScrollLink
                  to="pricing"
                  smooth={true}
                  duration={50}
                  className={`navbar-link ${
                    activeMenuItem === "pricing" ? "active" : ""
                  }`}
                  onClick={() => setActiveMenuItem("pricing")}
                >
                  Pricing
                </ScrollLink>
              </li>
              <li className="navbar-item">
                <ScrollLink
                  to="contactUs"
                  smooth={true}
                  duration={50}
                  className={`navbar-link ${
                    activeMenuItem === "contactUs" ? "active" : ""
                  }`}
                  onClick={() => setActiveMenuItem("contactUs")}
                >
                  Contact us
                </ScrollLink>
              </li>
              <li>
                {isLargeScreen && (
                  <TranslateComponent />
                )}
              </li>
              {isAuthenticated ? (
                <Link to="/dashboard/main">
                  <button className="login-btn">
                    <FaRegUser /> <span>Dashboard</span>
                  </button>
                </Link>
              ) : (
                <Link to="/login">
                  <button className="login-btn">
                    <FaRegUser /> <span>Login Your Account</span>
                  </button>
                </Link>
              )}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
