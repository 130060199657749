import { lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import PrivacyPolicyOne from "../pages/privacyPolicyOne/PrivacyPolicyOne";
const PrivacyPolicy = lazy(() =>
  import("../pages/privacy-policy/PrivacyPolicy")
);
const TermsCondition = lazy(() =>
  import("../pages/termsCondition/TermsCondition")
);
const PrivateRoute = lazy(() => import("./PrivateRoute"));
const HomeScreen = lazy(() => import("../pages/home/HomeScreen"));
const SignupScreen = lazy(() => import("../pages/loginRegister/SignupScreen"));
const LoginScreen = lazy(() => import("../pages/loginRegister/LoginScreen"));
const ForgotPassword = lazy(() =>
  import("../pages/loginRegister/ForgotPassword")
);
const VerifyOtp = lazy(() => import("../pages/loginRegister/VerifyOtp"));
const ChangePassword = lazy(() =>
  import("../pages/loginRegister/ChangePassword")
);
const DashboardScreen = lazy(() =>
  import("../pages/dashboard/DashboardScreen")
);
const ForgotVerifyOtp = lazy(() =>
  import("../pages/loginRegister/ForgotVerifyOtp")
);
const SubscriptionScreen = lazy(() =>
  import("../pages/subscription/SubscriptionScreen")
);
const Thankyou = lazy(() => import("../pages/thankyou/Thankyou"));
const PaymentScreen = lazy(() => import("../pages/payment/PaymentScreen"));
const AddUserCard = lazy(() => import("../pages/adduser/AddUserCard"));
const AddToWalllet = lazy(() => import("../pages/addToWallet/AddToWalllet"));

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeScreen />} />
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/register" element={<SignupScreen />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/verify-otp" element={<VerifyOtp />} />
      <Route path="/forgot-verify-otp" element={<ForgotVerifyOtp />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/add-user/:token" element={<AddUserCard />} />
      <Route path="/add-to-wallet" element={<AddToWalllet />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/privacy-policy-1" element={<PrivacyPolicyOne />} />
      <Route path="/terms-condition" element={<TermsCondition />} />
      <Route element={<PrivateRoute />}>
        <Route path="/subscription" element={<SubscriptionScreen />} />
        <Route path="/thank-you" element={<Thankyou />} />
        <Route path="/dashboard/:type" element={<DashboardScreen />} />
        <Route path="/payment" element={<PaymentScreen />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AllRoutes;
