import {
  SET_ADDRESS_LIST,
  SET_ADD_ADDRESS,
  SET_ADD_BUSINESS,
  SET_ADD_PRODUCT_CATEGORY,
  SET_ADD_QR_CODE,
  SET_ADD_REWARD,
  SET_ADD_STAFF,
  SET_BUSINESS_LIST,
  SET_CATEGORY_LIST,
  SET_CLEAR_NOTIFICATION,
  SET_COUNTRY_LIST,
  SET_COUPON_APPLY,
  SET_COUPON_DELETE,
  SET_COUPON_LIST,
  SET_DASHBOARD_DETAILS,
  SET_DELETE_ADDRESS,
  SET_DELETE_BUSINESS,
  SET_DELETE_PRODUCT_CATEGORY,
  SET_DELETE_QR_CODE,
  SET_DELETE_REWARD,
  SET_DELETE_STAFF,
  SET_HOMEPAGE_DATA,
  SET_NOTIFICATION_ADD,
  SET_NOTIFICATION_LIST,
  SET_PAGES_DATA,
  SET_PLAN_DETAILS,
  SET_PRIVACY_POLICY_DATA,
  SET_PRODUCT_CATEGORY_LIST,
  SET_QR_CODE_DETAILS,
  SET_QR_CODE_LIST,
  SET_REVIEW_LIST,
  SET_REWARD_LIST,
  SET_SALES_LIST,
  SET_STAFF_LIST,
  SET_SUBSCRIPTION_CHARGE,
  SET_SUBSCRIPTION_LIST,
  SET_TERMS_DATA,
  SET_TRANSECTION_DETAILS,
  SET_UPDATE_PROFILE,
  SET_USER_DELETE,
  SET_USER_PROFILE,
} from "../types";

const initialState = {
  planDetails: JSON.parse(localStorage.getItem("planDetails"))
    ? JSON.parse(localStorage.getItem("planDetails"))
    : {},
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_PROFILE:
      return {
        ...state,
        userDetails: action.payload,
      };
    case SET_UPDATE_PROFILE:
      return {
        ...state,
        updateProfile: action.payload,
      };
    case SET_CATEGORY_LIST:
      return {
        ...state,
        categoryList: action.payload,
      };
    case SET_QR_CODE_LIST:
      return {
        ...state,
        qrCodeList: action.payload,
      };
    case SET_ADD_QR_CODE:
      return {
        ...state,
        addQrCode: action.payload,
      };
    case SET_DELETE_QR_CODE:
      return {
        ...state,
        deleteQrCode: action.payload,
      };
    case SET_REWARD_LIST:
      return {
        ...state,
        rewardList: action.payload,
      };
    case SET_SALES_LIST:
      return {
        ...state,
        salesList: action.payload,
      };
    case SET_ADD_REWARD:
      return {
        ...state,
        addReward: action.payload,
      };
    case SET_DELETE_REWARD:
      return {
        ...state,
        deleteReward: action.payload,
      };
    case SET_BUSINESS_LIST:
      return {
        ...state,
        businessList: action.payload,
      };
    case SET_ADD_BUSINESS:
      return {
        ...state,
        addBusiness: action.payload,
      };
    case SET_DELETE_BUSINESS:
      return {
        ...state,
        deleteBusiness: action.payload,
      };
    case SET_HOMEPAGE_DATA:
      return {
        ...state,
        homePageData: action.payload,
      };
    case SET_SUBSCRIPTION_LIST:
      return {
        ...state,
        subscriptionList: action.payload,
      };
    case SET_PLAN_DETAILS:
      return {
        ...state,
        planDetails: action.payload,
      };
    case SET_REVIEW_LIST:
      return {
        ...state,
        reviewList: action.payload,
      };
    case SET_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: action.payload,
      };
    case SET_CLEAR_NOTIFICATION:
      return {
        ...state,
        clearNotification: action.payload,
      };
    case SET_ADDRESS_LIST:
      return {
        ...state,
        addressList: action.payload,
      };
    case SET_ADD_ADDRESS:
      return {
        ...state,
        addAddress: action.payload,
      };
    case SET_DELETE_ADDRESS:
      return {
        ...state,
        deleteAddress: action.payload,
      };
    case SET_TRANSECTION_DETAILS:
      return {
        ...state,
        transectionDetails: action.payload,
      };
    case SET_DASHBOARD_DETAILS:
      return {
        ...state,
        dashboardDetails: action.payload,
      };
    case SET_COUPON_LIST:
      return {
        ...state,
        couponList: action.payload,
      };
    case SET_COUPON_APPLY:
      return {
        ...state,
        couponApply: action.payload,
      };
    case SET_COUPON_DELETE:
      return {
        ...state,
        couponDelete: action.payload,
      };
    case SET_USER_DELETE:
      return {
        ...state,
        userDelete: action.payload,
      };
    case SET_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload,
      };
    case SET_STAFF_LIST:
      return {
        ...state,
        staffList: action.payload,
      };
    case SET_ADD_STAFF:
      return {
        ...state,
        addStaff: action.payload,
      };
    case SET_DELETE_STAFF:
      return {
        ...state,
        deleteStaff: action.payload,
      };
    case SET_NOTIFICATION_ADD:
      return {
        ...state,
        notificationAdd: action.payload,
      };
    case SET_QR_CODE_DETAILS:
      return {
        ...state,
        qrCodeDetails: action.payload,
      };
    case SET_PRODUCT_CATEGORY_LIST:
      return {
        ...state,
        productCategoryList: action.payload,
      };
    case SET_ADD_PRODUCT_CATEGORY:
      return {
        ...state,
        addProductCategory: action.payload,
      };
    case SET_DELETE_PRODUCT_CATEGORY:
      return {
        ...state,
        deleteProductCategory: action.payload,
      };
    case SET_SUBSCRIPTION_CHARGE:
      return {
        ...state,
        subsciptionCharge: action.payload,
      };
    case SET_PRIVACY_POLICY_DATA:
      return {
        ...state,
        privacyData: action.payload,
      };
    case SET_TERMS_DATA:
      return {
        ...state,
        termsData: action.payload,
      };
    default:
      return state;
  }
};

export default homeReducer;
