import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyA7A-ZQariTndp1GqkYxb1m0yfh7aKsDMQ",
  authDomain: "loyalty-card-generator-ad964.firebaseapp.com",
  projectId: "loyalty-card-generator-ad964",
  storageBucket: "loyalty-card-generator-ad964.appspot.com",
  messagingSenderId: "581262041861",
  appId: "1:581262041861:web:bedd069499a1bab1cd8d6c",
};

initializeApp(firebaseConfig);

const firebaseApp = initializeApp(firebaseConfig);

let messaging;

if (await isSupported()) {
  messaging = getMessaging(firebaseApp);
}

export const requestForToken = (setTokenFound) => {
  getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        setTokenFound(currentToken);
      } else {
        setTokenFound(false);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
