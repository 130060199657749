export const SET_BTN_LOADING = "SET_BTN_LOADING";

export const SET_PAGE_LOADING = "SET_PAGE_LOADING";

export const SET_USER_PROFILE = "SET_USER_PROFILE";

export const SET_QR_CODE_LIST = "SET_QR_CODE_LIST";

export const SET_ADD_QR_CODE = "SET_ADD_QR_CODE";

export const SET_DELETE_QR_CODE = "SET_DELETE_QR_CODE";

export const SET_BUSINESS_LIST = "SET_BUSINESS_LIST";

export const SET_ADD_BUSINESS = "SET_ADD_BUSINESS";

export const SET_DELETE_BUSINESS = "SET_DELETE_BUSINESS";

export const SET_REWARD_LIST = "SET_REWARD_LIST";

export const SET_ADD_REWARD = "SET_ADD_REWARD";

export const SET_DELETE_REWARD = "SET_DELETE_REWARD";

export const SET_SALES_LIST = "SET_SALES_LIST";

export const SET_CATEGORY_LIST = "SET_CATEGORY_LIST";

export const SET_FORGOT_PASSWORD_ERROR = "SET_FORGOT_PASSWORD_ERROR";

export const SET_SUBSCRIPTION_LIST = "SET_SUBSCRIPTION_LIST";

export const SET_PLAN_DETAILS = "SET_PLAN_DETAILS";

export const SET_HOMEPAGE_DATA = "SET_HOMEPAGE_DATA";

export const SET_REVIEW_LIST = "SET_REVIEW_LIST";

export const SET_NOTIFICATION_LIST = "SET_NOTIFICATION_LIST";

export const SET_CLEAR_NOTIFICATION = "SET_CLEAR_NOTIFICATION";

export const SET_ADDRESS_LIST = "SET_ADDRESS_LIST";

export const SET_ADD_ADDRESS = "SET_ADD_ADDRESS";

export const SET_DELETE_ADDRESS = "SET_DELETE_ADDRESS";

export const SET_UPDATE_PROFILE = "SET_UPDATE_PROFILE";

export const SET_TRANSECTION_DETAILS = "SET_TRANSECTION_DETAILS";

export const SET_DASHBOARD_DETAILS = "SET_DASHBOARD_DETAILS";

export const SET_COUPON_LIST = "SET_COUPON_LIST";

export const SET_COUPON_APPLY = "SET_COUPON_APPLY";

export const SET_COUPON_DELETE = "SET_COUPON_DELETE";

export const SET_USER_DELETE = "SET_USER_DELETE";

export const SET_COUNTRY_LIST = "SET_COUNTRY_LIST";

export const SET_STAFF_LIST = "SET_STAFF_LIST";

export const SET_ADD_STAFF = "SET_ADD_STAFF";

export const SET_DELETE_STAFF = "SET_DELETE_STAFF";

export const SET_NOTIFICATION_ADD = "SET_NOTIFICATION_ADD";

export const SET_QR_CODE_DETAILS = "SET_QR_CODE_DETAILS";

export const SET_PRODUCT_CATEGORY_LIST = "SET_PRODUCT_CATEGORY_LIST";

export const SET_ADD_PRODUCT_CATEGORY = "SET_ADD_PRODUCT_CATEGORY";

export const SET_DELETE_PRODUCT_CATEGORY = "SET_DELETE_PRODUCT_CATEGORY";

export const SET_SUBSCRIPTION_CHARGE = "SET_SUBSCRIPTION_CHARGE";

export const SET_PRIVACY_POLICY_DATA = "SET_PRIVACY_POLICY_DATA";
export const SET_TERMS_DATA = "SET_TERMS_DATA";

export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
