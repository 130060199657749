import ErrorPopup from "../../popups/ErrorPopup";
import axiosInstance from "../axiosInstance";
import { SET_ACCESS_TOKEN, SET_BTN_LOADING, SET_PAGE_LOADING } from "../types";

export const setBtnLoading = (data) => (dispatch) => {
  dispatch({
    type: SET_BTN_LOADING,
    payload: data,
  });
};

export const setPageLoading = (data) => (dispatch) => {
  dispatch({
    type: SET_PAGE_LOADING,
    payload: data,
  });
};

export const setAccessToken = (data) => (dispatch) => {
  dispatch({
    type: SET_ACCESS_TOKEN,
    payload: data,
  });

  localStorage.setItem("loyalty_token", data);
};

export const onLogin = (data, navigate, setErrorPopup) => async (dispatch) => {
  try {
    const res = await axiosInstance.post("/login", data);
    console.log(res)
    if (res.data.status) {
      dispatch(setAccessToken(res.data.auth_token));
      dispatch(setBtnLoading(false));
      if (res.data.data.status_payment === "1") {
        navigate("/dashboard/main");
      } else {
        navigate("/subscription");
      }
      window.location.reload();
    }
  } catch (err) {
    dispatch(setBtnLoading(false));

    setErrorPopup(
      <ErrorPopup
        popup={setErrorPopup}
        message={err?.response?.data?.message}
      />
    );
  }
};

export const onRegister =
  (data, navigate, setErrorPopup) => async (dispatch) => {
    try {
      const res = await axiosInstance.post("/register", data);
      if (res.data.status) {
        navigate("/verify-otp");
        dispatch(setBtnLoading(false));
      }
    } catch (err) {
      setErrorPopup(
        <ErrorPopup
          popup={setErrorPopup}
          message={err?.response?.data?.message}
        />
      );
      dispatch(setBtnLoading(false));
    }
  };

export const onVerifyOtp =
  (data, navigate, type, setErrorPopup) => async (dispatch) => {
    try {
      const res = await axiosInstance.post("/otp-verify", data);
      if (res.data.status) {
        if (type === "forgot-password") {
          navigate("/change-password");
        } else if (type === "register") {
          navigate("/login");
        }
        dispatch(setBtnLoading(false));
      }
    } catch (err) {
      setErrorPopup(
        <ErrorPopup
          popup={setErrorPopup}
          message={err?.response?.data?.message}
        />
      );
      dispatch(setBtnLoading(false));
    }
  };

export const onForgotPassword =
  (data, navigate, setErrorPopup) => async (dispatch) => {
    try {
      const res = await axiosInstance.post("/forgot-password", data);
      if (res.data.status) {
        navigate("/forgot-verify-otp");
        dispatch(setBtnLoading(false));
      }
    } catch (err) {
      setErrorPopup(
        <ErrorPopup
          popup={setErrorPopup}
          message={err?.response?.data?.message}
        />
      );
      dispatch(setBtnLoading(false));
    }
  };

export const onResetPassword =
  (data, navigate, setErrorPopup) => async (dispatch) => {
    try {
      const res = await axiosInstance.post("/reset-password", data);
      if (res.data.status) {
        navigate("/login");
        dispatch(setBtnLoading(false));
      }
    } catch (err) {
      setErrorPopup(
        <ErrorPopup
          popup={setErrorPopup}
          message={err?.response?.data?.message}
        />
      );
      dispatch(setBtnLoading(false));
    }
  };

export const onResendOtp =
  (data, setOtpSuccess, setErrorPopup) => async (dispatch) => {
    try {
      const res = await axiosInstance.post("/resend-otp", data);
      if (res.data.status) {
        setOtpSuccess(res.data.message);
        dispatch(setBtnLoading(false));
      }
    } catch (err) {
      setErrorPopup(
        <ErrorPopup
          popup={setErrorPopup}
          message={err?.response?.data?.message}
        />
      );
      dispatch(setBtnLoading(false));
    }
  };
