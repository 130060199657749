import React from "react";
import "./popups.scss";

const SuccessPopup = ({ Popup, message }) => {
  return (
    <>
      <div className="bg_wrapper_popup_new">
        <div className="popup_box_bpn success_popup pb-4 rounded transparant_box">
          <div className="popup_header pb-0">
            <div className="p_header_hding"></div>
          </div>
          <div className="success_heading ">
            <svg
              viewBox="0 0 256 256"
              width="100"
              height="100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect fill="none" height="256" width="256" />
              <path
                d="M40,114.7V56a8,8,0,0,1,8-8H208a8,8,0,0,1,8,8v58.7c0,84-71.3,111.8-85.5,116.5a7.2,7.2,0,0,1-5,0C111.3,226.5,40,198.7,40,114.7Z"
                fill="none"
                stroke="#0077d8"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="8"
              />
              <polyline
                fill="none"
                points="172 104 113.3 160 84 132"
                stroke="#0077d8"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="8"
              />
            </svg>
          </div>
          <div className="sucess_message">
            <div>{message}</div>
          </div>

          <div className="success_popup_btn ">
            <button onClick={() => Popup()}>CLOSE</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessPopup;
