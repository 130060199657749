import React, { useEffect, useRef, useState } from "react";
import "./Translation.scss";

const TranslateComponent = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let intervalId = null;
    // Load the google translate programmaticaly...
    // debugger;
    const script = document.createElement("script");
    script.src =
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.id = "google_script";
    script.async = true;
    document.body.appendChild(script);

    const checkGoogleTranslate = () => {
      // debugger;
      if (
        window.google &&
        window.google.translate &&
        window.google.translate?.TranslateElement?.InlineLayout &&
        !isLoaded
      ) {
        clearInterval(intervalId);

        // Translate Box child
        const translateBoxItem = document.createElement("div");
        translateBoxItem.id = "google_translate_element";
        document.querySelector(".translate-box").appendChild(translateBoxItem);

        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            includedLanguages:
              "af,ach,ak,am,ar,az,be,bem,bg,bh,bn,br,bs,ca,chr,ckb,co,crs,cs,cy,da,de,ee,el,en,eo,es,es-419,et,eu,fa,fi,fo,fr,fy,ga,gaa,gd,gl,gn,gu,ha,haw,hi,hr,ht,hu,hy,ia,id,ig,is,it,iw,ja,jw,ka,kg,kk,km,kn,ko,kri,ku,ky,la,lg,ln,lo,loz,lt,lua,lv,mfe,mg,mi,mk,ml,mn,mo,mr,ms,mt,ne,nl,nn,no,nso,ny,nyn,oc,om,or,pa,pcm,pl,ps,pt-BR,pt-PT,qu,rm,rn,ro,ru,rw,sd,sh,si,sk,sl,sn,so,sq,sr,sr-ME,st,su,sv,sw,ta,te,tg,th,ti,tk,tl,tn,to,tr,tt,tum,tw,ug,uk,ur,uz,vi,wo,xh,yi,yo,zh-CN,zh-TW,zu",
            layout:
              window.google.translate.TranslateElement.InlineLayout.VERTICAL,
          },
          translateBoxItem
        );
        setIsLoaded(true);
      }
    };

    script.addEventListener("load", () => {
      intervalId = setInterval(checkGoogleTranslate, 100);
    });
    return () => {
      clearInterval(intervalId);

      // Remove the google translate when page is closed/changed
      document.querySelector("#google_script").remove();
      let googGtTt = document.querySelector("#goog-gt-tt");
      if (googGtTt) {
        return googGtTt.remove();
      }
      let VIpgJd = document.querySelector(".VIpgJd-ZVi9od-aZ2wEe-wOHMyf");
      if (VIpgJd) {
        return VIpgJd.remove()
      }
      const existingScript = document.getElementById("google-translate-script");
      if (existingScript) {
        existingScript.parentNode.removeChild(existingScript);
      }
    };
  }, []);

  return (
    <>
      <div className="language">
        <div className="translate-box"></div>
      </div>
      <style>{
      `
          body{
            top: 0 !important;
          }
          .VIpgJd-ZVi9od-aZ2wEe-wOHMyf{
            display:none !important;
          }
      `
    }</style>
    </>
  );
};

export default TranslateComponent;
