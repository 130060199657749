import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import moment from "moment/moment";

const Footer = ({ homePageData }) => {
  return (
    <>
      <div className="footer-wrapper">
        <div className="container">
          <div className="footer-main">
            <img src="/assets/footer-logo.png" alt="" className="footer-logo" />
            <ul className="footer-menu-list">
              <li className="menu-item">
                <ScrollLink
                  to="homeSection"
                  smooth={true}
                  duration={50}
                  className="menu-link"
                >
                  Home
                </ScrollLink>
              </li>
              <li className="menu-item">
                <ScrollLink
                  to="aboutUs"
                  smooth={true}
                  duration={50}
                  className="menu-link"
                >
                  About us
                </ScrollLink>
              </li>
              <li className="menu-item">
                <ScrollLink
                  to="howItWork"
                  smooth={true}
                  duration={50}
                  className="menu-link"
                >
                  How it Work
                </ScrollLink>
              </li>
              <li className="menu-item">
                <ScrollLink
                  to="contactUs"
                  smooth={true}
                  duration={50}
                  className="menu-link"
                >
                  Contact us
                </ScrollLink>
              </li>

              <li className="menu-item">
                <Link to="/register" className="menu-link">
                  Become a member
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/privacy-policy" className="menu-link">
                  Privacy Policy
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/terms-condition" className="menu-link">
                  Terms & Conditions
                </Link>
              </li>
              {/* <li className="menu-item">
                <Link to="/login" className="menu-link">
                  Login
                </Link>
              </li> */}
            </ul>
            <div className="social-icons-list">
              <a href={homePageData?.facebook} target="_blank" rel="noreferrer">
                <img src="/assets/facebook.svg" alt="" />
              </a>
              <a href={homePageData?.twitter} target="_blank" rel="noreferrer">
                <img src="/assets/twitter.svg" alt="" />
              </a>
              {/* <a href={homePageData?.google} target="_blank" rel="noreferrer">
                <img src="/assets/google (2).svg" alt="" />
              </a> */}
              <a
                href={homePageData?.instagram}
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/instagram.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
        <img
          src="/assets/footer-first-arrow.svg"
          alt=""
          className="first-arrow"
        />
        <img
          src="/assets/footer-second-arrow.svg"
          alt=""
          className="second-arrow"
        />
      </div>
      <div className="copyright-footer">
        © Copyright {moment(new Date()).format("YYYY")}. All Rights Reserved -
        Loyalty Card
      </div>
    </>
  );
};

export default Footer;
