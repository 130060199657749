import React from "react";
import { FaRegUser } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import TranslateComponent from "../translation/Translation.jsx";

const ResponsiveNavbar = ({
  setShowNavbar,
  showNavbar,
  pathname,
  wrapperRef,
  isAuthenticated,
}) => {
  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div
      ref={wrapperRef}
      className={`responsivenavbar-wrapper ${showNavbar && "active-navbar"}`}
    >
      <div className="navbar-header">
        <Link to="/" onClick={() => setShowNavbar(false)}>
          <div className="dashboard-logo">
            <img src="/assets/logo.png" alt="" />
          </div>
        </Link>
        <i
          className="fa fa-times"
          aria-hidden="true"
          onClick={() => setShowNavbar(false)}
        ></i>
      </div>
      {pathname === "/login" ||
      pathname === "/forgot-password" ||
      pathname === "/forgot-verify-otp" ||
      pathname === "/change-password" ||
      pathname === "/register" ||
      pathname === "/verify-otp" ? null : pathname === "/dashboard/main" ||
        pathname === "/dashboard/business" ||
        pathname === "/dashboard/my-qr-code" ||
        pathname === "/dashboard/my-scans" ||
        pathname === "/dashboard/rewards" ||
        pathname === "/dashboard/address" ||
        pathname === "/dashboard/notifications" ||
        pathname === "/dashboard/coupon" ||
        pathname === "/dashboard/profile" ||
        pathname === "/dashboard/how-to-use" ||
        pathname === "/dashboard/plans" ||
        pathname === "/dashboard/my-staff" ? (
        <ul className="navbar-list responsive-list">
          <li className="navbar-item responsive-item">
            <Link
              onClick={() => setShowNavbar(false)}
              style={{ textDecoration: "none", color: "#000" }}
              to="/dashboard/profile"
            >
              Profile
            </Link>
          </li>
          <li className="navbar-item responsive-item">
            <Link
              onClick={() => setShowNavbar(false)}
              style={{ textDecoration: "none", color: "#000" }}
              to="/dashboard/main"
            >
              Dashboard
            </Link>
          </li>
          <li className="navbar-item responsive-item">
            <Link
              onClick={() => setShowNavbar(false)}
              style={{ textDecoration: "none", color: "#000" }}
              to="/dashboard/business"
            >
              Business
            </Link>
          </li>
          <li className="navbar-item responsive-item">
            <Link
              onClick={() => setShowNavbar(false)}
              style={{ textDecoration: "none", color: "#000" }}
              to="/dashboard/my-qr-code"
            >
              My QR Code
            </Link>
          </li>
          <li className="navbar-item responsive-item">
            <Link
              onClick={() => setShowNavbar(false)}
              style={{ textDecoration: "none", color: "#000" }}
              to="/dashboard/my-scans"
            >
              My Scans
            </Link>
          </li>
          <li className="navbar-item responsive-item">
            <Link
              onClick={() => setShowNavbar(false)}
              style={{ textDecoration: "none", color: "#000" }}
              to="/dashboard/my-staff"
            >
              My Staff
            </Link>
          </li>
          {/* <li className="navbar-item responsive-item">
            <Link
              onClick={() => setShowNavbar(false)}
              style={{ textDecoration: "none", color: "#000" }}
              to="/dashboard/rewards"
            >
              Rewards
            </Link>
          </li> */}
          <li className="navbar-item responsive-item">
            <Link
              onClick={() => setShowNavbar(false)}
              style={{ textDecoration: "none", color: "#000" }}
              to="/dashboard/address"
            >
              Address
            </Link>
          </li>
          <li className="navbar-item responsive-item">
            <Link
              onClick={() => setShowNavbar(false)}
              style={{ textDecoration: "none", color: "#000" }}
              to="/dashboard/notifications"
            >
              Notification
            </Link>
          </li>
          {/* <li className="navbar-item responsive-item">
            <Link
              onClick={() => setShowNavbar(false)}
              style={{ textDecoration: "none", color: "#000" }}
              to="/dashboard/coupon"
            >
              Coupon
            </Link>
          </li> */}
          <li className="navbar-item responsive-item">
            <Link
              onClick={() => setShowNavbar(false)}
              style={{ textDecoration: "none", color: "#000" }}
              to="/dashboard/plans"
            >
              Subscription Plan
            </Link>
          </li>
          <li className="navbar-item responsive-item">
            <Link
              onClick={() => setShowNavbar(false)}
              style={{ textDecoration: "none", color: "#000" }}
              to="/dashboard/how-to-use"
            >
              How to Use Dashboard and Scanner
            </Link>
          </li>
          <li className="navbar-item responsive-item" onClick={handleLogout}>
            Logout
          </li>
          <div className="re-download-icons">
            <a
              href="https://play.google.com/store/apps/details?id=com.LoyaltyCardGenerator&pli=1"
              target="_blank"
            >
              <img src="/assets/googleplay.png" alt="" />
            </a>
            <img
              src="/assets/appstore.png"
              alt=""
              style={{ marginLeft: "20px" }}
            />
          </div>
        </ul>
      ) : pathname === "/privacy-policy" ||
        pathname === "/terms-condition" ||
        pathname === "/privacy-policy-1" ? (
        <ul className="navbar-list responsive-list">
          <li className="navbar-item responsive-item">
            <Link
              to="/"
              className="navbar-link active"
              onClick={() => setShowNavbar(false)}
            >
              Home
            </Link>
          </li>
          <li className="navbar-item responsive-item">
            <Link
              to="/"
              className="navbar-link"
              onClick={() => setShowNavbar(false)}
            >
              About us
            </Link>
          </li>
          <li className="navbar-item responsive-item">
            <Link
              to="/"
              className="navbar-link"
              onClick={() => setShowNavbar(false)}
            >
              How it work
            </Link>
          </li>
          <li className="navbar-item responsive-item">
            <Link
              to="/"
              className="navbar-link"
              onClick={() => setShowNavbar(false)}
            >
              Contact us
            </Link>
          </li>
          <li>
            {/* <TranslateComponent /> */}
          </li>
          {isAuthenticated ? (
            <Link to="/dashboard/main" onClick={() => setShowNavbar(false)}>
              <button className="login-btn">
                <FaRegUser /> <span>Dashboard</span>
              </button>
            </Link>
          ) : (
            <Link to="/login" onClick={() => setShowNavbar(false)}>
              <button className="login-btn responsive-login-btn">
                <FaRegUser /> <span>Login Your Account</span>
              </button>
            </Link>
          )}
        </ul>
      ) : (
        <ul className="navbar-list responsive-list">
          <li className="navbar-item responsive-item">
            <ScrollLink
              to="homeSection"
              smooth={true}
              duration={50}
              className="navbar-link active"
              onClick={() => setShowNavbar(false)}
            >
              Home
            </ScrollLink>
          </li>
          <li className="navbar-item responsive-item">
            <ScrollLink
              to="aboutUs"
              smooth={true}
              duration={50}
              className="navbar-link"
              onClick={() => setShowNavbar(false)}
            >
              About us
            </ScrollLink>
          </li>
          <li className="navbar-item responsive-item">
            <ScrollLink
              to="howItWork"
              smooth={true}
              duration={50}
              className="navbar-link"
              onClick={() => setShowNavbar(false)}
            >
              How it work
            </ScrollLink>
          </li>
          <li className="navbar-item responsive-item">
            <ScrollLink
              to="contactUs"
              smooth={true}
              duration={50}
              className="navbar-link"
              onClick={() => setShowNavbar(false)}
            >
              Contact us
            </ScrollLink>
          </li>
          <li>
            {/* <TranslateComponent /> */}
          </li>
          {isAuthenticated ? (
            <Link to="/dashboard/main" onClick={() => setShowNavbar(false)}>
              <button className="login-btn">
                <FaRegUser /> <span>Dashboard</span>
              </button>
            </Link>
          ) : (
            <Link to="/login" onClick={() => setShowNavbar(false)}>
              <button className="login-btn responsive-login-btn">
                <FaRegUser /> <span>Login Your Account</span>
              </button>
            </Link>
          )}
        </ul>
      )}
    </div>
  );
};

export default ResponsiveNavbar;
