import { SET_ACCESS_TOKEN, SET_BTN_LOADING, SET_PAGE_LOADING } from "../types";

const initialState = {
  btnLoading: false,
  pageLoading: false,
  isAuthenticated: localStorage.getItem("loyalty_token")
    ? localStorage.getItem("loyalty_token")
    : "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BTN_LOADING:
      return {
        ...state,
        btnLoading: action.payload,
      };
    case SET_PAGE_LOADING:
      return {
        ...state,
        pageLoading: action.payload,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        isAuthenticated: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
