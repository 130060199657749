import React, { Suspense, useState } from "react";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import AllRoutes from "./routes/AllRoutes";
import Navbar from "./components/navbar/Navbar";
import Loading from "./components/loading/Loading";
import { onMessageListener, requestForToken } from "./firebase";

const App = () => {
  const pathname = useLocation()?.pathname;
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);
  requestForToken(setTokenFound);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  function getDeviceTypeFromFCMToken(fcmToken) {
    if (fcmToken.startsWith("c:") || fcmToken.startsWith("e:")) {
      return "Android";
    } else if (fcmToken.startsWith("apns:") || fcmToken.startsWith("gcm:")) {
      return "iOS";
    } else {
      return "Unknown";
    }
  }

  // Example usage
  const fcmToken = isTokenFound; // Replace this with your actual FCM token
  if (isTokenFound) {
    const deviceType = getDeviceTypeFromFCMToken(fcmToken);
  }

  return (
    <>
      <Navbar pathname={pathname} />
      <Suspense fallback={<Loading />}>
        <AllRoutes />
      </Suspense>
    </>
  );
};

export default App;
